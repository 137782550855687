import React, { useState, useEffect } from 'react';
import { appBaseState, AppStateInterface } from '../stores/app';
import { State, useHookstate } from '@hookstate/core';
import { Switch, Route, useHistory, BrowserRouter } from 'react-router-dom';
import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import Box from "@amzn/awsui-components-react/polaris/box";
import Link from "@amzn/awsui-components-react/polaris/link";
import Navigation from './Navigation';
import RequestInstance from './RequestInstance';
import PreviousBuilds from './PreviousBuilds';
import LookupRequest from './LookupRequest';
import { Auth } from 'aws-amplify';
import { Spinner } from '@amzn/awsui-components-react';

export function App() {
    const [authLoaded, setAuthLoaded] = useState(false);
    const appState = useHookstate(appBaseState);
    const history = useHistory();
    let groups = [];

    const setUserData = (cognitoUserData: any) => {
        const idToken = cognitoUserData = cognitoUserData.getSignInUserSession().getIdToken();
        const userId = idToken.payload['identities'][0].userId;
        try {
            groups = idToken.payload['custom:posix'].split(',');
        } catch {
            groups = [];
        }

        appState.username.set(userId);
        appState.userGroups.set(groups);
    }

    useEffect(() => {
        Auth.currentAuthenticatedUser().then(cognitoUserData => {
            setUserData(cognitoUserData);
            setAuthLoaded(true);
        }).catch((err) => {
            console.log('No user signed in.');
            console.log(err);
            setAuthLoaded(true);
            Auth.federatedSignIn({
                customProvider: 'AmazonFederate'
            }).catch(err => {
                console.error(`Error trying to authenticate user`, err);
            });
        });
    }, []);

    if (authLoaded) {
        if (appState.username.value && appState.userGroups.value.length > 0) {
            return (
                <AppLayout
                    content={
                        <Switch>
                            <Route path="/" exact component={RequestInstance} />
                            <Route path="/builds" component={PreviousBuilds} />
                            <Route path="/request/:requestID" component={LookupRequest} />
                        </Switch>
                    }
                    navigation={<Navigation history={history} />}
                    toolsHide={true}
                />
            )
        } else {
            return (
                <div>
                    <Box
                        margin={{ top: "s", left: "s"}}
                        color="inherit"
                        display="block"
                        fontSize="heading-l"
                    >
                        You do not have access to this page. Please contact
                        <Link external href="https://w.amazon.com/index.php/SIDE#HContactus"> SIDE Support</Link> if you have
                        any questions.
                    </Box>
                </div>
            )
        }
    } else {
        return (<Spinner size="large" />);
    }
}

export default App;