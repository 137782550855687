import { createState } from '@hookstate/core';
import { useHistory } from "react-router-dom";

export interface AppStateInterface {
    username: string | undefined;
    userGroups: string[];
}

export const appBaseState = createState<AppStateInterface>({
    username: undefined,
    userGroups: []
});