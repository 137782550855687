import { Header, Pagination, Table, Link, Box, TextFilter, Button, Icon, StatusIndicator, Popover, PropertyFilter } from '@amzn/awsui-components-react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import React, { ReactNode, useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { useHookstate } from '@hookstate/core';
import { appBaseState } from 'src/stores/app';
import { useHistory } from 'react-router-dom';

const queries = require("../../utils/graphql/queries.js");
const subscriptions = require("../../utils/graphql/subscriptions.js");

interface EmptyStateProps {
    title: string;
    subtitle: string;
}

export function EmptyState({ title, subtitle }: EmptyStateProps) {
    return (
        <Box textAlign="center" color="inherit">
            <Box variant="strong" textAlign="center" color="inherit">
                {title}
            </Box>
            <Box variant="p" padding={{ bottom: 's' }} color="inherit">
                {subtitle}
            </Box>
        </Box>
    );
}

export default function PreviousBuilds() {
    const [workflowItems, setWorkflowItems] = useState([]);
    const [workflowTableLoading, setWFTableLoading] = useState(true);
    const [refreshTable, setRefreshTable] = useState(true);
    const history = useHistory();

    useEffect(() => {
        async function callListWorkflows() {
            setWFTableLoading(true);

            try {
                const resp: any = await API.graphql({
                    query: queries.listECBakeWorkflowModels,
                    variables: {
                        limit: 300
                    }
                });

                // temp1.sort((a, b) => (a.wf_request_received_ts_utc < b.wf_request_received_ts_utc))

                const items = (resp.data.listECBakeWorkflowModels.items).sort((a: any, b: any) => (
                    a.wf_request_received_ts_utc < b.wf_request_received_ts_utc
                ))

                setWorkflowItems(items);
                setWFTableLoading(false);
            } catch (e) {
                console.log('Error trying to call listECBakeWorkflowModels:', e);
                setWFTableLoading(false);
            }
        }

        callListWorkflows();
    }, [refreshTable]);
    
    return (
        <div>
            <BuildTable 
                workflowItems={workflowItems}
                setWorkflowItems={setWorkflowItems}
                workflowTableLoading={workflowTableLoading}
                setWFTableLoading={setWFTableLoading}
                setRefreshTable={setRefreshTable}
                refreshTable={refreshTable}
                history={history}
            />
        </div>
    );
}

function BuildTable(props: {
    workflowItems: any
    setWorkflowItems: Function
    workflowTableLoading: boolean
    setWFTableLoading: Function
    setRefreshTable: Function
    refreshTable: boolean
    history: any
}) {
    const requestLink = (req_id: string) => {
        return (
            <Link href={`/request/${req_id}`} onFollow={(ev) => {ev.preventDefault(); props.history.push(ev.detail.href)}} >
                {req_id}
            </Link>
        );
    }

    const phoneToolLink = (alias: string) => {
        return (
            <Link target='_blank' href={`https://phonetool.amazon.com/users/${alias}`} >
                {alias}
            </Link>
        );
    }

    const { items, actions, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps } = useCollection(
        props.workflowItems || [],
        {
            propertyFiltering: {
                filteringProperties: [
                    {
                        propertyLabel: 'Build status',
                        key: 'wf_build_state',
                        groupValuesLabel: 'Build status values',
                        operators: [':', '!:', '=', '!=']
                    },
                    {
                        propertyLabel: 'Requester',
                        key: 'requested_by_alias',
                        groupValuesLabel: 'Requester values',
                        operators: [':', '!:', '=', '!=']
                    },
                    {
                        propertyLabel: 'Instance name',
                        key: 'instance_name',
                        groupValuesLabel: 'Instance name values',
                        operators: [':', '!:', '=', '!=']
                    },
                    {
                        propertyLabel: 'Hostclass',
                        key: 'hostclass',
                        groupValuesLabel: 'Hostclass values',
                        operators: [':', '!:', '=', '!=']
                    },
                    {
                        propertyLabel: 'Service name',
                        key: 'service_name',
                        groupValuesLabel: 'Service name values',
                        operators: [':', '!:', '=', '!=']
                    },
                    {
                        propertyLabel: 'Instance type',
                        key: 'instance_type',
                        groupValuesLabel: 'Instance type values',
                        operators: [':', '!:', '=', '!=']
                    },
                    {
                        propertyLabel: 'Instance AZ',
                        key: 'instance_az',
                        groupValuesLabel: 'Instance AZ values',
                        operators: [':', '!:', '=', '!=']
                    }
                ],
                empty: (
                    <EmptyState
                        title="No workflows"
                        subtitle="No recent workflow builds to display."
                    />
                ),
                noMatch: (
                    <EmptyState
                        title="No matches"
                        subtitle="We can't find a match."
                    />
                )
            },
            pagination: {
                pageSize:50
            },
            sorting: {},
            selection: {}
        }
    )

    const requestTimeRelative = (ts: string) => {
        const d = new Date(parseInt(ts));
        const pst_ts = d.toLocaleString('en-US', { timeZone: 'America/Los_Angeles', timeZoneName: 'short' })

        return (
            <Popover
                size="small"
                position="top"
                dismissButton={false}
                content={d.toUTCString()}
            >
                {pst_ts}
            </Popover>
        );
    }

    const buildStatus = (state: string) => {
        switch(state) {
            case 'WAITING_TO_START': 
                return (
                    <StatusIndicator type="pending">Waiting in queue</StatusIndicator>
                );
            case 'COMPLETE': 
                return (
                    <StatusIndicator type="success">Ready</StatusIndicator>
                    );
            case 'COMPLETE_WITH_WARNINGS': 
                return (
                    <StatusIndicator type="warning">Ready, but needs attention</StatusIndicator>
                );
            case 'BUILDING': 
                return (
                    <StatusIndicator type="in-progress">In Progress</StatusIndicator>
                );
            case 'FAILED': 
                return (
                    <StatusIndicator type="error">Failed</StatusIndicator>
                );
            default:
                return (
                    <StatusIndicator type="warning">Unknown</StatusIndicator>
                );
        }
    }

    return (
        <Table
            {...collectionProps}
            columnDefinitions={[
                {
                    id: "build_status",
                    header: "Build Status",
                    sortingField: "wf_build_state",
                    cell: (e: any) => buildStatus(e.wf_build_state)
                },
                {
                    id: "request_id",
                    header: "Request ID",
                    sortingField: "request_id",
                    cell: (e: any) => requestLink(e.request_id)
                },
                {
                    id: "instance_name",
                    header: "Instance Name",
                    sortingField: "instance_name",
                    cell: (e: any) => e.instance_name
                },
                {
                    id: "hostclass",
                    header: "Hostclass",
                    sortingField: "hostclass",
                    cell: (e: any) => e.hostclass
                },
                {
                    id: "service_name",
                    header: "Service Name",
                    sortingField: "service_name",
                    cell: (e: any) => e.service_name
                },
                {
                    id: "instance_type",
                    header: "Instance Type",
                    sortingField: "instance_type",
                    cell: (e: any) => e.instance_type
                },
                {
                    id: "instance_az",
                    header: "Instance AZ",
                    sortingField: "instance_az",
                    cell: (e: any) => e.instance_az
                },
                {
                    id: "request_time",
                    header: "Request Time",
                    sortingField: "request_time",
                    sortingComparator: (a, b) => {return a.wf_request_received_ts_utc - b.wf_request_received_ts_utc},
                    cell: (e: any) => requestTimeRelative(e.wf_request_received_ts_utc)
                },
                {
                    id: "requester",
                    header: "Requester",
                    sortingField: 'requester',
                    cell: (e: any) => phoneToolLink(e.requested_by_alias)
                },
                
            ]}
            items={items}
            sortingDisabled={false}
            loading={props.workflowTableLoading}
            loadingText="Grabbing recent build requests"
            empty={
                <Box textAlign="center" color="inherit">
                    <b>No builds</b>
                    <Box
                        padding={{ bottom: "s" }}
                        variant="p"
                        color="inherit"
                    >
                        No recent workflows to display.
                    </Box>
                </Box>
            }
            filter={
                <PropertyFilter
                    i18nStrings={{
                        filteringAriaLabel: 'your choice',
                        dismissAriaLabel: 'Dismiss',

                        filteringPlaceholder: 'Search',
                        groupValuesText: 'Values',
                        groupPropertiesText: 'Properties',
                        operatorsText: 'Operators',

                        operationAndText: 'and',
                        operationOrText: 'or',

                        operatorLessText: 'Less than',
                        operatorLessOrEqualText: 'Less than or equal',
                        operatorGreaterText: 'Greater than',
                        operatorGreaterOrEqualText: 'Greater than or equal',
                        operatorContainsText: 'Contains',
                        operatorDoesNotContainText: 'Does not contain',
                        operatorEqualsText: 'Equals',
                        operatorDoesNotEqualText: 'Does not equal',

                        editTokenHeader: 'Edit filter',
                        propertyText: 'Property',
                        operatorText: 'Operator',
                        valueText: 'Value',
                        cancelActionText: 'Cancel',
                        applyActionText: 'Apply',
                        allPropertiesLabel: 'All properties',

                        tokenLimitShowMore: 'Show more',
                        tokenLimitShowFewer: 'Show fewer',
                        clearFiltersText: 'Clear filters',
                        removeTokenButtonAriaLabel: () => 'Remove token',
                        enteredTextLabel: text => `Use: "${text}"`,
                    }}
                    {...propertyFilterProps}
                />
            }
            header={<Header
                actions={
                    <Button onClick={() => {props.setRefreshTable(!props.refreshTable)}} loading={props.workflowTableLoading}>Refresh</Button>
                }
            >
                EC-BakeOven Recent Builds
            </Header>}
            pagination={
                <Pagination {...paginationProps} />
            }
        />
    );
}