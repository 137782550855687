import React from "react";
import { useLocation, RouteComponentProps } from 'react-router-dom';
import SideNavigation, { SideNavigationProps } from "@amzn/awsui-components-react/polaris/side-navigation";

export default function Navigation(props: NavigationProps) {
    const location = useLocation();
    const routes: SideNavigationProps.Item[] = [
        { type: "link", text: "Request Instance", href: "/" },
        // { type: "link", text: "Request Instance", href: "/new" },
        { type: "link", text: "Previous Requests", href: "/builds" }
    ];

    return (
        <SideNavigation
            activeHref={location.pathname.startsWith('/request') ? '/builds' : location.pathname}
            header={{ text: "EC-BakeOven", href: "/" }}
            onFollow={(e) => {
                e.preventDefault();
                props.history.push(e.detail.href);
            }}
            items={routes}
        />
    );
}

interface NavigationProps {
    history: RouteComponentProps['history']
};