import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Amplify, Auth } from 'aws-amplify';
import App from './components/App';
import AppInitWrapper from './components/AppInitWrapper';
import './i18n';
import '@amzn/awsui-global-styles/polaris.css';

console.log(`Loading stack configuration for stage prod`);


const callbackUrl = window.location.origin;
const config = {
    oauth: {
        domain: 'side-ecbake-prod.auth.us-east-1.amazoncognito.com',
        scope: ['openid'],
        redirectSignIn: callbackUrl,
        redirectSignOut: callbackUrl,
        responseType: 'code',
    },
    // Cognito options
    aws_cognito_identity_pool_id: 'us-east-1:f825da9f-f0e7-4fd4-96ff-05b9b53fb14d',
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_eT7me1BV2',
    aws_user_pools_web_client_id: '5ulre8t6t3beec6ncn8ksgib7v',
    // AppSync
    aws_appsync_graphqlEndpoint: 'https://yw6pcj3uo5dypmxp6kuwhhgbjq.appsync-api.us-east-1.amazonaws.com/graphql',
    aws_appsync_region: 'us-east-1',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    API: {
        graphql_endpoint: "https://yw6pcj3uo5dypmxp6kuwhhgbjq.appsync-api.us-east-1.amazonaws.com/graphql",
        graphql_headers: async () => ({
            'Authorization': (await Auth.currentAuthenticatedUser()).getSignInUserSession().getIdToken().getJwtToken(),
            'AuthUser': (await Auth.currentAuthenticatedUser()).getUsername()
        })
    },
}

Amplify.configure(config);


ReactDOM.render(
  <React.StrictMode>
    <AppInitWrapper>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </AppInitWrapper>
  </React.StrictMode>,
  document.getElementById('root')
);
