// Using static data for page layout testing / demoing

export const APP_STAGES = [
    {
        label: 'Alpha',
        value: 'alpha'
    },
    {
        label: 'Beta',
        value: 'beta'
    },
    {
        label: 'Gamma',
        value: 'gamma'
    },
    {
        label: 'Prod',
        value: 'prod'
    }
]

export const OS_OPTIONS = [
    {
        label: 'EC-Bake Windows Server 2012',
        value: 'windows-2012'
    },
    {
        label: 'EC-Bake Windows Server 2016',
        value: 'windows-2016'
    },
    {
        label: 'EC-Bake Windows Server 2019',
        value: 'windows-2019'
    },
    {
        label: 'EC-Bake Windows Server 2022',
        value: 'windows-2022'
    },
];

export const LOCAL_ADMIN_GROUPS = [
  {value: 'hostclass-gs-bosch-windows-local-administrators'},
  {value: 'hostclass-gs-db-windows-local-administrators'},
  {value: 'hostclass-gs-lenel-app-windows-local-administrators'},
  {value: 'hostclass-gs-lenel-com-windows-local-administrators'},
  {value: 'hostclass-gs-rightcrowd-windows-local-administrators'},
  {value: 'hostclass-sig-cs-windows-local-administrators'},
  {value: 'hostclass-sig-infra-windows-local-administrators'},
];

export const AEP_PATCH_OPTIONS = [
  {value: '', label: 'None (requires a ticket to infosec)'},
  {value: 'GS-EMEA-AEP-2200Z-Standard', label: 'GS-EMEA-AEP-2200Z-Standard'},
  {value: 'GS-APAC-AEP-1200Z-Standard', label: 'GS-APAC-AEP-1200Z-Standard'},
  {value: 'GS-AMER-AEP-0300Z-Standard', label: 'GS-AMER-AEP-0300Z-Standard'}
]

export const AEP_PATCH_GROUPS = [
  {value: 'AEP-Win-Braveheart-0', label: 'AEP-Win-Braveheart-0'},
  {value: 'AEP-Win-Braveheart-1', label: 'AEP-Win-Braveheart-1'},
  {value: 'AEP-Win-Standard-0', label: 'AEP-Win-Standard-0'},
  {value: 'AEP-Win-Standard-1', label: 'AEP-Win-Standard-1'}
]

export const APP_HOST_TYPES = [
    {
      "label": "gs-app",
      "value": "gs-app"
    },
    {
      "label": "gs-com",
      "value": "gs-com"
    },
    {
      "label": "gs-db",
      "value": "gs-db"
    },
    {
      "label": "gs-fs",
      "value": "gs-fs"
    },
    {
      "label": "gs-iis",
      "value": "gs-iis"
    },
    {
      "label": "gs-job",
      "value": "gs-job"
    },
    {
      "label": "gs-sto",
      "value": "gs-sto"
    },
    {
      "label": "gs-ts",
      "value": "gs-ts"
    },
    {
      "label": "gs-coa",
      "value": "gs-coa"
    },
    {
      "label": "gs-con",
      "value": "gs-con"
    },
    {
      "label": "gs-coi",
      "value": "gs-coi"
    },
    {
      "label": "ac-ccn",
      "value": "ac-ccn"
    },
    {
      "label": "ac-cci",
      "value": "ac-cci"
    },
    {
      "label": "ac-app",
      "value": "ac-app"
    },
    {
      "label": "ac-db",
      "value": "ac-db"
    },
    {
      "label": "ac-fs",
      "value": "ac-fs"
    },
    {
      "label": "ac-iis",
      "value": "ac-iis"
    },
    {
      "label": "ac-job",
      "value": "ac-job"
    },
    {
      "label": "ac-sto",
      "value": "ac-sto"
    },
    {
      "label": "ac-ts",
      "value": "ac-ts"
    }
];

export const AVAIL_REGIONS = [
    {
      label: 'IAD',
      value: 'us-east-1',
      description: 'N. Virginia (us-east-1)'
    },
    {
        label: 'PDX',
        value: 'us-west-2',
        description: 'Oregon (us-west-2)'
    },
    {
        label: 'DUB',
        value: 'eu-west-1',
        description: 'Ireland (eu-west-1)'
    },
];

export const AZ_OPTIONS : {[key: string]: any} = {
    'us-east-1': [
        {
            label: "us-east-1a",
            value: "us-east-1a"
        },
        {
            label: "us-east-1c",
            value: "us-east-1c"
        },
        {
            label: "us-east-1d",
            value: "us-east-1d"
        },
        {
            label: "us-east-1e",
            value: "us-east-1e"
        }
    ],
    'us-west-2': [
        {
            label: "us-west-2a",
            value: "us-west-2a"
        },
        {
            label: "us-west-2b",
            value: "us-west-2b"
        },
        {
            label: "us-west-2c",
            value: "us-west-2c"
        }
    ],
    'eu-west-1': [
        {
            label: "eu-west-1a",
            value: "eu-west-1a"
        },
        {
            label: "eu-west-1b",
            value: "eu-west-1b"
        },
        {
            label: "eu-west-1c",
            value: "eu-west-1c"
        }
    ]
};