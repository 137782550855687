import { API, graphqlOperation } from 'aws-amplify';
import { Input } from '../type/input';

const mutations = require("../graphql/mutations.js")
const queries = require("../graphql/queries.js")

const buildAPICalls = (reqData: any) => {
    const reqs = reqData.instance_az.map((az: string) => {
        return ({
            ...reqData,
            instance_az: az
        })
    });

    const workflowReqs = reqs.map((req: any) => {
        return (
            API.graphql({
                query: mutations.startWorkflow,
                variables: {
                    input: {
                        stage: 'prod',
                        requestData: JSON.stringify(req)
                    }
                }
            })
        )
    });

    return workflowReqs;
}

export const callSubmitWorkflow = (reqData: any) => {
    const workflowReqs = buildAPICalls(reqData);

    const workflowResp = Promise.allSettled(workflowReqs).then(resp => {
        return resp;
    }).catch(err => {
        console.log('Received error from workflow submission..', err);
        return err;
    });

    return workflowResp;
}
