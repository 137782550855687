import React, { useCallback, useEffect, useState } from 'react';
import { 
    Container, 
    Tiles, 
    Multiselect, 
    Form, 
    Button, 
    Header, 
    SpaceBetween, 
    FormField, 
    Grid, 
    Input, 
    Select, 
    Box,
    ColumnLayout,
    AttributeEditor,
    Autosuggest,
    Modal,
    Alert,
    Checkbox,
    ExpandableSection,
    Link
} from '@amzn/awsui-components-react/polaris';
import { AVAIL_REGIONS, AZ_OPTIONS, APP_STAGES, OS_OPTIONS, LOCAL_ADMIN_GROUPS, APP_HOST_TYPES, AEP_PATCH_OPTIONS, AEP_PATCH_GROUPS } from './config';
import { useHookstate } from '@hookstate/core';
import { appBaseState } from 'src/stores/app';
import { callSubmitWorkflow } from '../../utils/appsync/workflow-requests';

function invalidHostclass(hc: string) {
    // https://w.amazon.com/bin/view/HostClass/#Hostclass_Constraints
    let hostclass = hc.toUpperCase();
    
    if (hostclass.length < 1 || hostclass.length > 255) // length
        return true;
    if (new RegExp('(^\-|\-$|\-ROLE)').test(hostclass)) // starts or ends in - or ends in -Role
        return true;
    if (new RegExp('^[0-9A-Z]([0-9A-Z-]*[A-Z]?$)').test(hostclass)) // valid characters; return valid
        return false;

    return true; // default to invalid
};

// to-do: This dev branch is a mess, merge page changes from feature/prod-ts-changes for release
export default function RequestInstanceForm() {
    const appState = useHookstate(appBaseState);

    const [requestFormDisabled, setRequestFormDisabled] = useState(true);
    const [requestLoading, setRequestLoading] = useState(false);
    const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);
    const [workflowFailuresVisible, setWorkflowFailuresVisible] = useState(false);
    const [workflowSuccessVisible, setWorkflowSuccessVisible] = useState(false);
    const [workflowFailureContent, setWorkflowFailure] = useState('');
    const [workflowRequestIds, setWorkflowRequestIds] = useState([]);
    const [workflowFailedIds, setWorkflowFailedIds] = useState([]);
    const [instanceRegion, setInstanceRegion] = useState('us-east-1');
    const [hostclass, setHostclass] = useState('');
    const [serviceName, setServiceName] = useState('');
    const [instanceDescription, setInstanceDescription] = useState('');
    const [IAMRole, setIAMRole] = useState('');
    const [selectedAZs, setSelectedAZs] = useState([
        {
            label: "us-east-1a",
            value: "us-east-1a"
        }]
    );
    const [selectedStage, setSelectedStage] = useState(
        {
            label: "Alpha",
            value: "alpha"
        }
    );
    const [selectedOS, setSelectedOS] = useState(
        {
            label: "EC-Bake Windows Server 2019",
            value: "windows-2019"
        }
    );
    const [selectedHostType, setSelectedHostType] = useState(
        {
            label: "gs-app",
            value: "gs-app"
        }
    );
    const [selectedPatchWindow, setSelectedPatchWindow] = useState(
        {
            label: '',
            value: 'A patch window is required'
        }
    );
    const [selectedPatchGroup, setSelectedPatchGroup] = useState(
        {
            label: '',
            value: 'A patch group is required'
        }
    );
    const [selectedInstanceType, setSelectedInstanceType] = useState({label: '', value: ''});
    const [selectedAdminGroups, setSelectedAdminGroups] = useState<Array<AdminGroupItem>>([]);
    const [requestData, setRequestData] = useState({});

    useEffect(() => {
        if (selectedAZs.length < 1 ||
            selectedAdminGroups.filter(e => e.name).length < 1 ||
            serviceName.length < 1 ||
            !AEP_PATCH_OPTIONS.map(e => e.value).includes(selectedPatchWindow.value) ||
            (!AEP_PATCH_GROUPS.map(e => e.value).includes(selectedPatchGroup.value) 
                && (AEP_PATCH_OPTIONS.map(e => e.value).includes(selectedPatchWindow.value) && selectedPatchWindow.value != '')) ||
            invalidHostclass(hostclass)
        ) {
            setRequestFormDisabled(true);
        } else {
            setRequestFormDisabled(false);
        }
    });
    
    const handleReviewRequest = () => {
        setRequestFormDisabled(true);

        const user_request_data = {
            instance_region: instanceRegion.toLowerCase(),
            instance_az: selectedAZs.map(e => e.value),
            gso_stage: selectedStage.value,
            instance_type: selectedInstanceType.value,
            instance_os: selectedOS.value,
            hostclass: hostclass.toUpperCase(),
            gso_local_admin_groups: [...new Set(
                selectedAdminGroups.map((ev: any) => ev.name)
            )],
            gso_host_type: selectedHostType.value,
            aep_patch_window: selectedPatchWindow.value,
            aep_patch_group: selectedPatchGroup.value,
            service_name: serviceName.toUpperCase(),
            instance_description: instanceDescription,
            requested_by_alias: appState.username.value
        };

        setRequestData(user_request_data);
        setConfirmationModalVisible(true);
    }
    
    const submitWorkflow = async(requestData: any) => {
        setRequestLoading(true);
        setWorkflowRequestIds([]);
        setWorkflowSuccessVisible(false);

        await callSubmitWorkflow(requestData).then(resp => {
            console.log('Received response from call', resp);

            const submitted = resp.filter((req: any) => req.status === 'fulfilled');
            const failed = resp.filter((req: any) => req.status === 'rejected');


            if (submitted.length > 0) {
                const successful_workflows = submitted.map((req: any) => 
                    JSON.parse(req.value.data.startWorkflow).body
                );

                setWorkflowRequestIds(successful_workflows);
                setWorkflowSuccessVisible(true);
            }

            if (failed.length > 0) {
                const failed_reasons = failed.map((req: any) =>
                    req.reason
                );

                setWorkflowFailedIds(failed_reasons);
                setWorkflowFailuresVisible(true);
            }

        }).catch(err => {
            console.log('Could not send request to EC-BakeOven.', err);
            setWorkflowFailure(`Failed to send workflow request - ${err}`);
            setWorkflowFailuresVisible(true);
        });

        window.scrollTo(0, 0);
        setRequestLoading(false);
    }
    
    return (
        <div>
            <WorkflowSubmissionError 
                workflowFailuresVisible={workflowFailuresVisible}
                setWorkflowFailuresVisible={setWorkflowFailuresVisible}
                workflowFailureContent={workflowFailureContent}
                workflowFailedIds={workflowFailedIds}
            />
            <WorkflowSubmissionSuccess 
                workflowSuccessVisible={workflowSuccessVisible}
                setWorkflowSuccessVisible={setWorkflowSuccessVisible}
                workflowRequestIds={workflowRequestIds}
            />
            <ConfirmationModal 
                confirmationModalVisible={confirmationModalVisible} 
                setConfirmationModalVisible={setConfirmationModalVisible}
                setRequestFormDisabled={setRequestFormDisabled}
                submitWorkflow={submitWorkflow}
                requestData={requestData}
            />
            <Grid gridDefinition={[{
                colspan: {
                    xl: 5,
                    l: 6,
                    m: 7,
                    s: 10,
                    xxs: 12
                }
            }]} >
                <Form
                    header={
                        <Header variant='h1' description="Request a new pre-configured EC2 instance.">
                            Request EC2 Instance
                        </Header>
                    }
                    actions={
                        <SpaceBetween direction='horizontal' size='xs'>
                            <Button variant='primary' disabled={requestFormDisabled} loading={requestLoading} onClick={handleReviewRequest}>
                                Review Request
                            </Button>
                        </SpaceBetween>
                    }
                >
                    {/* Form components */}
                    <SpaceBetween size="l">
                        <DistributionDetails
                            instanceRegion={instanceRegion}
                            setInstanceRegion={setInstanceRegion}
                            selectedAZs={selectedAZs}
                            setSelectedAZs={setSelectedAZs}
                            selectedStage={selectedStage}
                            setSelectedStage={setSelectedStage}
                        />
                        <InstanceDetails
                            selectedInstanceRegion={instanceRegion}
                            selectedInstanceStage={selectedStage.value}
                            selectedInstanceType={selectedInstanceType}
                            setSelectedInstanceType={setSelectedInstanceType}
                            selectedOS={selectedOS}
                            setSelectedOS={setSelectedOS}
                            hostclass={hostclass}
                            setHostclass={setHostclass}
                            setIAMRole={setIAMRole}
                            selectedAdminGroups={selectedAdminGroups}
                            setSelectedAdminGroups={setSelectedAdminGroups}
                            selectedHostType={selectedHostType}
                            setSelectedHostType={setSelectedHostType}
                            selectedPatchWindow={selectedPatchWindow}
                            setSelectedPatchWindow={setSelectedPatchWindow}
                            selectedPatchGroup={selectedPatchGroup}
                            setSelectedPatchGroup={setSelectedPatchGroup}
                            serviceName={serviceName}
                            setServiceName={setServiceName}
                            instanceDescription={instanceDescription}
                            setInstanceDescription={setInstanceDescription}
                        />
                    </SpaceBetween>
                </Form>
            </Grid>
        </div>
    );
}

function ConfirmationModal(props: {
    requestData: any,
    confirmationModalVisible: boolean
    setConfirmationModalVisible: Function
    submitWorkflow: Function
    setRequestFormDisabled: Function
}) {
    const [loading, setLoading] = useState(false);
    const [agreed, setAgree] = useState(false);
    const handleModalClose = () => {
        props.setConfirmationModalVisible(false);
        props.setRequestFormDisabled(false);
    };
    const handleSubmitWorkflow = () => {
        setLoading(true);
        props.submitWorkflow(props.requestData);
        setLoading(false);
        props.setConfirmationModalVisible(false);
    }

    return (
        <Modal
            onDismiss={handleModalClose}
            visible={props.confirmationModalVisible}
            closeAriaLabel='Close'
            size='medium'
            footer={
                <div>
                    <Checkbox
                        checked={agreed}
                        onChange={(e) => setAgree(e.detail.checked)}
                    >
                        I acknowledge that I am authorized to request the instances and incur a cost to my department.
                    </Checkbox>
                    <Box float="right">
                        <SpaceBetween direction='horizontal' size='xs'>
                            <Button variant='link' onClick={handleModalClose}>Cancel</Button>
                            <Button variant='primary' disabled={!agreed} loading={loading} onClick={handleSubmitWorkflow}>Submit</Button>
                        </SpaceBetween>
                    </Box>
                </div>
            }
            header="Confirm instance details"
        >
            <ColumnLayout columns={2} variant='text-grid'>
                <SpaceBetween size='s'>
                    <div>
                        <Box variant="awsui-key-label">Hostclass</Box>
                        <div>{props.requestData.hostclass}</div>
                    </div>
                    <div>
                        <Box variant="awsui-key-label">Pre-existing IAM Role</Box>
                        <div>{`${props.requestData.hostclass}-Role`}</div>
                    </div>
                    <div>
                        <Box variant="awsui-key-label">Service name</Box>
                        <div>{props.requestData.service_name}</div>
                    </div>
                    <div>
                        <Box variant="awsui-key-label">Host type</Box>
                        <div>{props.requestData.gso_host_type}</div>
                    </div>
                    <div>
                        <Box variant="awsui-key-label">Host stage</Box>
                        <div>{props.requestData.gso_stage}</div>
                    </div>
                    <div>
                        <Box variant="awsui-key-label">Requester</Box>
                        <div>{props.requestData.requested_by_alias}</div>
                    </div>
                </SpaceBetween>
                <SpaceBetween size='s'>
                    <div>
                        <Box variant="awsui-key-label">Instance distribution</Box>
                        {props.requestData.instance_az != undefined ? (
                            <ul>
                                {props.requestData.instance_az.map((az: string) => (
                                    <li key={az}>1x <strong>{props.requestData.instance_type}</strong> for {az}</li>
                                ))}
                            </ul>
                        ): null}
                    </div>
                    <div>
                        <Box variant="awsui-key-label">Instance OS</Box>
                        <div>{props.requestData.instance_os}</div>
                    </div>
                    <div>
                        <Box variant="awsui-key-label">AEP patch window</Box>
                        <div>{props.requestData.aep_patch_window == '' ? 'None' : props.requestData.aep_patch_window}</div>
                    </div>
                    <div>
                        <Box variant="awsui-key-label">AEP patch group</Box>
                        <div>{props.requestData.aep_patch_group == '' ? 'None' : props.requestData.aep_patch_group}</div>
                    </div>
                    <div>
                        <Box variant="awsui-key-label">Description</Box>
                        {props.requestData.instance_description != undefined && props.requestData.instance_description.length > 0 ? (
                            <div>{props.requestData.instance_description}</div>
                        ): <div>-</div>}
                    </div>
                </SpaceBetween>
            </ColumnLayout>
        </Modal>
    );
}

function WorkflowSubmissionError(props: {
    workflowFailuresVisible: boolean,
    setWorkflowFailuresVisible: Function,
    workflowFailureContent: string,
    workflowFailedIds: string[]
}) {
    return (
        <Alert
            visible={props.workflowFailuresVisible}
            dismissible
            type="error"
            header="Failed to submit request"
        >
            Failed to submit instance request(s). More details can be found below.

            <ExpandableSection header="Failures">
                <ul>
                    {props.workflowFailedIds.length > 1 ? props.workflowFailedIds.map(id => (
                        <li key={id}>{id}</li>
                    )): <li>{props.workflowFailureContent}</li>}
                </ul>
            </ExpandableSection>
        </Alert>
    );
}

function WorkflowSubmissionSuccess(props: {
    workflowSuccessVisible: boolean,
    setWorkflowSuccessVisible: Function,
    workflowRequestIds: string[]
}) {
    const openTrackingRequests = () => {
        if (props.workflowRequestIds.length > 0)
            props.workflowRequestIds.forEach(req => {
                window.open(`/request/${req}`, '_blank');
            });
    }

    return (
        <Alert
            visible={props.workflowSuccessVisible}
            action={
                <Button
                    iconAlign='right'
                    iconName='external'
                    onClick={openTrackingRequests}
                >
                    View request(s)
                </Button>
            }
            dismissible={true}
            onDismiss={(ev) => props.setWorkflowSuccessVisible(false)}
            type="success"
            header="Workflow requests successfully submitted"
        >
            Successfully submitted {props.workflowRequestIds.length} instance request(s). To open the tracking page for your 
            workflows, please click on the 'View requests' button, or visit the build status page. Your individual workflow ID(s) 
            can be found below.

            <ExpandableSection header="Workflows">
                <ul>
                    {props.workflowRequestIds.map(id => (
                        <li key={id}>
                            <Link
                                external
                                externalIconAriaLabel="Opens in a new tab"
                                href={`/request/${id}`}
                                target='_blank'
                            >
                                {`${id}`}
                            </Link>
                        </li>
                    ))}
                </ul>
            </ExpandableSection>
        </Alert>
    );
}

interface AdminGroupItem {
    name: string;
    value: string;
}

function updateAdminGroup(items: Array<AdminGroupItem>, itemIndex: number, updater: (group: AdminGroupItem) => AdminGroupItem) {
    return items.map((item, idx) => idx === itemIndex ? updater(item): item);
}

function InstanceDetails(props: {
    selectedInstanceRegion: string,
    selectedInstanceStage: string,
    selectedInstanceType: any,
    setSelectedInstanceType: Function,
    selectedOS: any,
    setSelectedOS: Function,
    hostclass: any,
    setHostclass: Function,
    setIAMRole: Function,
    selectedAdminGroups: any,
    setSelectedAdminGroups: Function,
    selectedHostType: any,
    setSelectedHostType: Function,
    setSelectedPatchWindow: Function,
    selectedPatchWindow: any,
    setSelectedPatchGroup: Function,
    selectedPatchGroup: any,
    serviceName: any,
    setServiceName: Function,
    instanceDescription: any,
    setInstanceDescription: Function
}) {
    const [availableInstanceOptions, setAvailableInstanceOptions] = useState([]);
    const [validHostclass, setValidHostclass] = useState(false);

    const handleInstanceChange = (ev: any) => {
        const val = ev.detail.selectedOption;
        props.setSelectedInstanceType(val);
    }
    const handleOSChange = (ev: any) => {
        const val = ev.detail.selectedOption;
        props.setSelectedOS(val);
    }
    const handleHostTypeChange = (ev: any) => {
        const val = ev.detail.selectedOption;
        props.setSelectedHostType(val);
    }
    const handlePatchTimeChange = (ev: any) => {
        const val = ev.detail.selectedOption;
        props.setSelectedPatchWindow(val);
        // reset patch group
        props.setSelectedPatchGroup({
            label: 'A patch group is required',
            value: ''
        });
        
    }
    const handlePatchGroupChange = (ev: any) => {
        const val = ev.detail.selectedOption;
        props.setSelectedPatchGroup(val);
    }
    const handleDescriptionChange = (ev: any) => {
        const val = ev.detail.value;
        props.setInstanceDescription(val);
    }
    const handleHostclassChange = (ev: any) => {
        const val = ev.detail.value;
        props.setHostclass(val);
        props.setIAMRole(`${val}-Role`);

        if (invalidHostclass(val)) {
            setValidHostclass(false);
        } else {
            setValidHostclass(true);
        }
    }

    const handleServiceNameChange = (ev: any) => {
        const val = ev.detail.value;
        props.setServiceName(val);
    }

    const isPatchGroupInvalid = (patchGroup: string) => {
        if (AEP_PATCH_GROUPS.map(e => e.value).includes(patchGroup))
            return false;
 
        return true;
    }

    const isPatchWindowInvalid = (patchWindow: string) => {
        if (AEP_PATCH_OPTIONS.map(e => e.value).includes(patchWindow))
            return false;

        return true;
    }

    useEffect(() => {
        (async () => {
            console.log('Requesting available instances..');
            const avail_instances: any = (await import('./avail_instances.json')).default;
            const instance_options = avail_instances.map((i: any) => ({
                label: i.instance_type,
                value: i.instance_type,
                iconName: 'settings',
                tags:[
                    `vCPU: ${i.vcpu_count}`,
                    `Mem (GiB): ${i.memory_avail_gb}`
                ]
            }));
            setAvailableInstanceOptions(instance_options);
            props.setSelectedInstanceType(instance_options.find((i: any) => i.label === 'm5.xlarge'));
        })();
    }, [props.selectedInstanceRegion]);

    return (
        <Container header={<Header>Instance Configuration</Header>}>
            <SpaceBetween size="m">
                <FormField label="Instance Type" description="The available instance type to use. Only instance types available in your selected region will be shown." stretch={false}>
                    <Select
                        selectedOption={props.selectedInstanceType}
                        onChange={handleInstanceChange}
                        options={availableInstanceOptions}
                        filteringType="auto"
                    />
                </FormField>
                <FormField label="Operating System" stretch={false} description="OS to use with instances. These are custom images pre-configured by EC-BakeOven.">
                    {/* <Select options={APP_STAGES} selectedOption={props.selectedStage} onChange={handleStageChange}/> */}
                    <Select options={OS_OPTIONS} selectedOption={props.selectedOS} onChange={handleOSChange}/>
                </FormField>

                <FormField 
                    label="Hostclass" 
                    stretch={false} 
                    description="Existing hostclass for billing." 
                    constraintText="You must be an owner of the hostclass."
                    errorText={validHostclass ? '' : 'Invalid hostclass'}
                >
                    <Input 
                        invalid={props.hostclass.length < 1 ? true: false} 
                        value={props.hostclass.toUpperCase()} 
                        onChange={handleHostclassChange}
                        placeholder={`GS-ECBAKE-NA-EAST-${props.selectedInstanceStage}`.toUpperCase()}
                    />
                </FormField>

                <FormField label="IAM Role" stretch={false} description="IAM role to use with instance profile." constraintText="This IAM role must already exist in the sot-aws-corp-vpc account. If it does not exist, the workflow will fail.">
                    <Input disabled={true} value={
                        props.hostclass === '' ? '' : 
                        `${props.hostclass.toUpperCase()}-Role`
                    } onChange={handleHostclassChange}/>
                </FormField>
                <FormField 
                    errorText={props.selectedAdminGroups.length < 1 ? 'Please add at least one member to the Administrator group.': ''}
                    label="Admin Group Members" 
                    description="Groups / users to add to Administrators group once instance joins the Amazon domain." 
                    stretch={true}
                >
                    <AttributeEditor<AdminGroupItem>
                        addButtonText='Add member'
                        removeButtonText='Remove member'
                        empty="No members associated with the instance."
                        items={props.selectedAdminGroups}
                        definition={[
                            {
                                label: <span>Member</span>,
                                control: (item, index) => (
                                    <Autosuggest
                                        invalid={item.name.length < 1 ? true : false}
                                        value={item.name}
                                        options={LOCAL_ADMIN_GROUPS}
                                        placeholder="Enter or select a group / user"
                                        empty="No pre-defined members found. Please type one in."
                                        onChange={event => props.setSelectedAdminGroups((groups: any) =>
                                            updateAdminGroup(groups, index, header => ({
                                                ...header, name: event.detail.value
                                            }))
                                        )}
                                        enteredTextLabel={val => `Use ${val}`}
                                    />
                                )
                            }
                        ]}
                        onAddButtonClick={() => props.setSelectedAdminGroups((groups: any) => groups.concat({
                            name: '',
                            value: ''
                        }))}
                        onRemoveButtonClick={event => props.setSelectedAdminGroups((groups: any) => groups.filter((item: string, index: number) =>
                            index !== event.detail.itemIndex)
                        )}
                    />
                </FormField>

                <FormField label="Host Type" stretch={false}>
                    <Select options={APP_HOST_TYPES} selectedOption={props.selectedHostType} onChange={handleHostTypeChange}/>
                </FormField>

                <div>
                    <Box variant='awsui-key-label'>Host Naming Example</Box>
                    <div>{`${props.selectedHostType.value}-${props.selectedInstanceRegion.split('-').map(e => e[0]).join('')}{az}{###}${props.selectedInstanceStage[0]}`}</div>
                </div>
                
                <div>
                    <Box variant='awsui-key-label'> Patching </Box>
                    For selecting the appropriate AEP fields below, if you are requiring the host to be auto patched please follow the guide located <Link external externalIconAriaLabel='Opens in a new tab' href='https://w.amazon.com/bin/view/SIDE/AEP/#HECBakeOven-Selections'>here</Link>
                </div>

                <FormField label="AEP Maintenance Window" stretch={false}>
                    <Select invalid={isPatchWindowInvalid(props.selectedPatchWindow.value)} options={AEP_PATCH_OPTIONS} selectedOption={props.selectedPatchWindow} onChange={handlePatchTimeChange}/>
                </FormField>

                {(AEP_PATCH_OPTIONS.map(e => e.value).includes(props.selectedPatchWindow.value) && props.selectedPatchWindow.value != '') ?
                    <FormField label="AEP Patch Group" stretch={false}>
                        <Select invalid={isPatchGroupInvalid(props.selectedPatchGroup.value)} options={AEP_PATCH_GROUPS} selectedOption={props.selectedPatchGroup} onChange={handlePatchGroupChange}/>
                    </FormField> : null}

                <FormField label="Service Name" stretch={false} description="Service name to use (e.g. GS-RIGHTCROWD-APP)">
                    <Input invalid={props.serviceName.length < 1 ? true : false} value={props.serviceName.toUpperCase()} onChange={handleServiceNameChange}/>
                </FormField>

                <FormField label="Description" stretch={false} description="Description to attach to instances.">
                    <Input value={props.instanceDescription} onChange={handleDescriptionChange}/>
                </FormField>
            </SpaceBetween>
        </Container>
    );
}

function DistributionDetails(props: {
    instanceRegion:any, 
    setInstanceRegion:Function, 
    selectedAZs:any, 
    setSelectedAZs:Function,
    selectedStage:any,
    setSelectedStage:Function
}) {
    useEffect(() => {
        setAvailableAZOptions(AZ_OPTIONS[props.instanceRegion]);
    }, []);

    const [availableAZOptions, setAvailableAZOptions] = useState([]);

    const handleRegionSelect = (ev: any) => {
        const val = ev.detail.value;
        props.setInstanceRegion(val);
        props.setSelectedAZs([AZ_OPTIONS[val][0]]);
        setAvailableAZOptions(AZ_OPTIONS[val])
    }

    const handleAZChange = (ev: any) => {
        props.setSelectedAZs(ev.detail.selectedOptions)
    }

    const handleStageChange = (ev: any) => {
        props.setSelectedStage(ev.detail.selectedOption)
    }

    return (
        <Container header={<Header>Distribution Details</Header>}>
            <SpaceBetween size="s">
                <FormField label="Region" stretch={true}>
                    <Tiles value={props.instanceRegion} items={AVAIL_REGIONS} onChange={handleRegionSelect}/>
                </FormField>

                <FormField 
                    errorText={props.selectedAZs.length < 1 ? 'Please select an AZ' : ''} 
                    label="Availability Zones" 
                    stretch={false}
                >
                    <Multiselect 
                        placeholder="Select AZs"
                        selectedAriaLabel="Selected"
                        disabled={availableAZOptions.length < 1 ? true : false} 
                        options={availableAZOptions} 
                        selectedOptions={props.selectedAZs} 
                        onChange={handleAZChange}
                        invalid={props.selectedAZs.length < 1 ? true : false}  
                    />
                </FormField>

                <FormField label="Stage" stretch={false}>
                    <Select options={APP_STAGES} selectedOption={props.selectedStage} onChange={handleStageChange}/>
                </FormField>
            </SpaceBetween>
        </Container>
    );
}