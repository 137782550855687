/* eslint-disable */
export const createECBakeWorkflowModel  = /* GraphQL */ `
  mutation CreateECBakeWorkflowModel($input: CreateECBakeWorkflowModelInput!) {
    createECBakeWorkflowModel(input: $input) {
      failed_admin_groups
      failed_app_verifications
      failed_hostclass_reason
      gso_host_type
      gso_stage
      hostclass
      instance_az
      instance_configure_status
      instance_description
      instance_launch_status
      instance_name
      instance_os
      instance_ready_status
      instance_region
      instance_request_status
      instance_type
      request_id
      requested_by_alias
      service_name
      wf_active
      wf_build_state
      wf_build_state_start_ts
      wf_build_step
      wf_build_step_start_ts
      wf_fail_details
      wf_fail_reason
      wf_request_completed_ts_utc
      wf_request_received_ts_utc
      wf_step_details
      wf_request_details
    }
  }
`;

export const deleteECBakeWorkflowModel = /* GraphQL */ `
  mutation DeleteECBakeWorkflowModel($input: DeleteECBakeWorkflowModelInput!) {
    deleteECBakeWorkflowModel(input: $input) {
      failed_admin_groups
      failed_app_verifications
      failed_hostclass_reason
      gso_host_type
      gso_stage
      hostclass
      instance_az
      instance_configure_status
      instance_description
      instance_launch_status
      instance_name
      instance_os
      instance_ready_status
      instance_region
      instance_request_status
      instance_type
      request_id
      requested_by_alias
      service_name
      wf_active
      wf_build_state
      wf_build_state_start_ts
      wf_build_step
      wf_build_step_start_ts
      wf_fail_details
      wf_fail_reason
      wf_request_completed_ts_utc
      wf_request_received_ts_utc
      wf_step_details
      wf_request_details
    }
  }
`;

export const updateECBakeWorkflowModel = /* GraphQL */ `
  mutation UpdateECBakeWorkflowModel($input: UpdateECBakeWorkflowModelInput!) {
    updateECBakeWorkflowModel(input: $input) {
      failed_admin_groups
      failed_app_verifications
      failed_hostclass_reason
      gso_host_type
      gso_stage
      hostclass
      instance_az
      instance_configure_status
      instance_description
      instance_launch_status
      instance_name
      instance_os
      instance_ready_status
      instance_region
      instance_request_status
      instance_type
      request_id
      requested_by_alias
      service_name
      wf_active
      wf_build_state
      wf_build_state_start_ts
      wf_build_step
      wf_build_step_start_ts
      wf_fail_details
      wf_fail_reason
      wf_request_completed_ts_utc
      wf_request_received_ts_utc
      wf_step_details
      wf_request_details
    }
  }
`;

export const startWorkflow = /* GraphQL */ `
  mutation StartWorkflow($input: WFInput!) {
    startWorkflow(input: $input)
  }
`;